import React, { useState } from "react";
import contactHero from "../images/contactHero.png";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Form, Alert } from "react-bootstrap";

const IndexPage = () => {
  let [mail, setMail] = useState("");
  let [name, setName] = useState("");
  let [content, setContent] = useState("");
  let [sendToMe, setSendToMe] = useState(false);
  let [sendOk, setSendOk] = useState(false);
  let [sending, setSending] = useState(false);
  function sendData(data) {
    setSending(true);
    var xhr = new XMLHttpRequest();
    var url = "https://hook.eu1.make.com/n55gknej2mkh1gs4ooc6lipmquqshbor";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setSendOk(true);
        setTimeout(() => {
          setSending(false);
        }, 5000);
        var json = JSON.parse(xhr.responseText);
        console.log(json.email + ", " + json.password);
      }
    };
    xhr.send(JSON.stringify(data));
  }
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="contact">
        <section>
          <div className="fluid-container">
            <div className="row">
              <img src={contactHero} alt="Contact" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <h1
                className="col-md-12 times"
                style={{ textAlign: "center", margin: "62px 0px" }}
              >
                Contact
              </h1>
            </div>
            <Alert show={sendOk} variant="success">
              <Alert.Heading>Votre demande a été envoyée</Alert.Heading>
              <p>
                Nous avons bien reçu votre demande. Nous vous recontacterons
                dans les plus brefs délais.
              </p>
            </Alert>
            <Form
              onSubmit={(evt) => {
                sendData({
                  name: name,
                  mail: mail,
                  content: content,
                  sendtome: sendToMe,
                });
                evt.preventDefault();
                evt.stopPropagation();
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      value={name}
                      placeholder="Nom"
                      onChange={(evt) => setName(evt.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Adresse mail</Form.Label>
                    <Form.Control
                      value={mail}
                      type="email"
                      placeholder="name@example.com"
                      onChange={(evt) => setMail(evt.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Votre demande</Form.Label>
                    <Form.Control
                      value={content}
                      as="textarea"
                      rows="8"
                      onChange={(evt) => setContent(evt.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Check
                      style={{ display: "inline-block" }}
                      type="checkbox"
                      value={sendToMe}
                      id={"checkboxContact"}
                      onChange={(evt) => {
                        setSendToMe(evt.target.checked);
                      }}
                    />
                    <Form.Label
                      style={{ display: "inline-block" }}
                      for={"checkboxContact"}
                    >
                      Envoyez moi une copie du message
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="col-md-12 center">
                  <button disabled={sending} type="submit" className="btn">
                    Envoyer
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
